import React, { useContext, useState } from "react";
import axios from "axios";
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthContext } from "../contexts/AuthContext";
import { devApi, prodApi } from "../data/api.js";

const LoginModal = ({ open, onClose }) => {
  const { loggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  if (error) {
    console.log(error);
  }

  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      // Send login request to the backend
      const tokenResponse = await axios.post(prodApi.login, {
        username,
        password,
      });
      const { token } = tokenResponse.data;

      // Store the token in local storage or a cookie
      localStorage.setItem("token", token);

      // Get User Info with Token
      const userResponse = await axios.get(prodApi.users, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: "Include",
      });

      // Store user data in local storage
      localStorage.setItem("user", JSON.stringify(userResponse.data));

      // Perform login logic
      setLoggedIn(true);
      setIsLoggedIn(userResponse.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid username or password.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      console.error("e no dey work", error);
    }
    onClose();
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (loggedIn) {
    return null; // If user is logged in, do not show the modal
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          p: 2,
          bgcolor: "grey",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ display: "grid", placeItems: "center" }}>
          <Typography variant="h6" gutterBottom>
            Login
          </Typography>
        </Box>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleChangeUsername}
        />
        <TextField
          type={showPassword ? "text" : "password"}
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleChangePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={toggleShowPassword}
                  edge="end"
                  sx={{ color: "white" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          sx={{
            height: "50px",
            marginTop: "35px",
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "rgba(128, 128, 128, 0.5)",
            },
          }}
          variant="contained"
          fullWidth
          onClick={handleLogin}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;
