import axios from "axios";

const devApi = {
  login: "http://localhost:6334/login",
  users: "http://localhost:6334/users",
  prices: "http://localhost:6334/prices",
};

const prodApi = {
  login: "https://www.data.banilux.ng/login",
  users: "https://www.data.banilux.ng/users",
  prices: "https://www.data.banilux.ng/prices",
};

const api = axios.create({
  baseURL: "http://localhost:6334/banilux",
});

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["authorization"];
  }
};

export { devApi, prodApi, setAuthToken };
