import React from "react";
import { Box, IconButton } from "@mui/material";

const LogoutButton = ({ handleLogout }) => {

  const logoutIcon = '../../images/icons/logout.png'

  return (
    <Box>
      <IconButton onClick={handleLogout}>
        <img 
          alt= "logout"
          width="20px"
          src={logoutIcon}
        />
      </IconButton>
    </Box>
  );
};

export default LogoutButton;
