import React, { useState, useEffect, useContext } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "../global/Header";
import Submenu from "../components/Submenu";
import { AuthContext } from "../contexts/AuthContext.jsx";
import { devApi, prodApi } from "../data/api";
import { getProductPricesByType, fetchData } from "../functions";
import LoadingIcon from "../components/LoadingIcon.jsx";

const Body = () => {
  const [data, setData] = useState([]);
  const { isLoggedIn } = useContext(AuthContext);
  const isTab = useMediaQuery("(max-width:770px)");

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        const fetchedData = await fetchData(prodApi.prices);
        setData(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataFromBackend();
  }, []);

  const beer = "Beer";
  const energy = "Energy Drinks";
  const soft = "Soft Drinks";
  const cocktail = "Cocktails, Juices and Yougurt";
  const vodka = "Vodka";
  const rum = "Rum";
  const tequila = "Tequila";
  const champagne = "Champagne and Sparkling Wine";
  const wine = "Red Wine";
  const nawine = "None Alcohol Wine";
  const cream = "Cream Liquor";
  const spirit = "Spirits";
  const aperitif = "Aperitif";
  const whiskey = "Whiskey vs. Whisky";
  const cognac = "Cognac";
  const water = "Water";
  const psoup = "Peppersoups";
  const meal = "Meals";
  const pmeat = "Peppered Meat";
  const shawarma = "Shawarma";
  const grill = "Grill";
  const takeaway = "Takeaway Pack";

  const beerList = getProductPricesByType(data, "beer");
  const energyList = getProductPricesByType(data, "energy drinks");
  const softList = getProductPricesByType(data, "soft drinks");
  const cocktailList = getProductPricesByType(data, "cocktails");
  const vodkaList = getProductPricesByType(data, "vodka");
  const rumList = getProductPricesByType(data, "rum");
  const tequilaList = getProductPricesByType(data, "tequila");
  const champagneList = getProductPricesByType(data, "champagne");
  const wineList = getProductPricesByType(data, "red wine");
  const nawineList = getProductPricesByType(data, "non alcoholic wine");
  const creamList = getProductPricesByType(data, "cream liquor");
  const spiritList = getProductPricesByType(data, "spirits");
  const aperitifList = getProductPricesByType(data, "aperitif");
  const whiskeyList = getProductPricesByType(data, "whiskey");
  const cognacList = getProductPricesByType(data, "cognac");
  const waterList = getProductPricesByType(data, "water");
  const psoupList = getProductPricesByType(data, "peppersoups");
  const mealList = getProductPricesByType(data, "meals");
  const pmeatList = getProductPricesByType(data, "peppered meat");
  const shawarmaList = getProductPricesByType(data, "shawarma");
  const grillList = getProductPricesByType(data, "grill");
  const takeawayList = getProductPricesByType(data, "takeaway");

  const category = {
    color: "white",
    fontSize: isTab ? "1.5rem" : "2.5rem",
    margin: "20px",
    marginTop: "40px",
    marginBottom: "0px",
    fontWeight: 100,
  };

  const header = {
    color: "#7aa648",
    textAlign: "center",
    fontSize: isTab ? "1.5rem" : "2.5rem",
  };

  const name = {
    color: "white",
    margin: "20px",
    marginBottom: "0px",
    fontSize: isTab ? "1.5rem" : "2.5rem",
  };

  return (
    <>
      <LoadingIcon />
      <Header />
      <Box sx={header}>PRICE MANAGEMENT SYSTEM</Box>
      <Box sx={name}>Welcome, {isLoggedIn.firstname}</Box>
      <Box sx={category}>Drinks</Box>
      <Submenu title={beer} priceList={beerList} />
      <Submenu title={energy} priceList={energyList} />
      <Submenu title={soft} priceList={softList} />
      <Submenu title={cocktail} priceList={cocktailList} />
      <Submenu title={vodka} priceList={vodkaList} />
      <Submenu title={rum} priceList={rumList} />
      <Submenu title={tequila} priceList={tequilaList} />
      <Submenu title={champagne} priceList={champagneList} />
      <Submenu title={wine} priceList={wineList} />
      <Submenu title={nawine} priceList={nawineList} />
      <Submenu title={cream} priceList={creamList} />
      <Submenu title={spirit} priceList={spiritList} />
      <Submenu title={aperitif} priceList={aperitifList} />
      <Submenu title={whiskey} priceList={whiskeyList} />
      <Submenu title={cognac} priceList={cognacList} />
      <Submenu title={water} priceList={waterList} />
      <Box sx={category}>Food</Box>
      <Submenu title={psoup} priceList={psoupList} />
      <Submenu title={meal} priceList={mealList} />
      <Submenu title={pmeat} priceList={pmeatList} />
      <Submenu title={shawarma} priceList={shawarmaList} />
      <Submenu title={grill} priceList={grillList} />
      <Submenu title={takeaway} priceList={takeawayList} />
    </>
  );
};

export default Body;
