import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
} from "@mui/material";
import axios from "axios";
import { devApi, prodApi } from "../data/api";

const Submenu = ({ title, priceList }) => {
  const isTab = useMediaQuery("(max-width:770px)");
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [message, setMessage] = useState("");

  const handlePriceChange = (productId, newPrice) => {
    setUpdatedPrices((prevState) => ({
      ...prevState,
      [productId]: newPrice,
    }));
  };

  const handleSubmit = () => {
    // Iterate through updated prices and update them in the backend
    Object.entries(updatedPrices).forEach(([product, newPrice]) => {
      updatePriceInBackend(product, newPrice);
    });
    setMessage("Prices Uploaded Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const updatePriceInBackend = async (product, newPrice) => {
    try {
      const response = await axios.post(prodApi.prices, {
        product: product,
        newPrice: newPrice,
      });
      console.log(response.data); // Log the response from the backend
    } catch (error) {
      console.error("Error updating price:", error);
    }
    console.log(`Updating price for product ${product} to ${newPrice}`);
  };

  return (
    <Box
      className="parent"
      sx={{
        width: "auto",
        color: "white",
        margin: "20px",
        marginTop: "0px",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          className="title"
          sx={{
            width: "100%",
            fontSize: isTab ? "1rem" : "1.5rem",
            fontWeight: 100,
          }}
        >
          {title}
        </Box>
        {priceList.map((item, index) => (
          <Box
            className="line"
            key={index}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor:
                index % 2 === 0
                  ? "rgba(255,255,255,0.2)"
                  : "rgba(255,255,255,0)",
              padding: "0px",
              fontWeight: 100,
            }}
          >
            <Box sx={{ fontSize: isTab ? "0.7rem" : "1rem", fontWeight: 200 }}>
              {item.product}
            </Box>
            <TextField
              type="number"
              defaultValue={item.price}
              onChange={(e) => handlePriceChange(item.product, e.target.value)}
              InputProps={{
                inputProps: { min: 0 },
                style: {
                  fontSize: isTab ? "0.7rem" : "1rem",
                  fontWeight: 800,
                  backgroundColor: "white",
                  padding: "0px",
                  width: isTab ? "80px" : "200px",
                  height: isTab ? "25px" : "30px",
                  padding: "0px",
                },
              }}
            />
          </Box>
        ))}
        {message && (
          <Typography variant="body2" color="#7aa648">
            {message}
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              margin: "20px",
              bgcolor: "#7aa648",
              "&:hover": { bgcolor: "grey" },
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Submenu;
