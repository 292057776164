import React, {useContext} from 'react'
import { Box, useMediaQuery } from "@mui/material"
import LogoutButton from '../components/LogoutButton'
import { AuthContext } from "../contexts/AuthContext.jsx";
import { title } from '../data/database'

const Header = () => {
  const { setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const isTab = useMediaQuery('(max-width:770px)')

  let logoutTimeout;

  const handleLogout = () => {
    clearTimeout(logoutTimeout);
    logoutTimeout = setTimeout(logoutUser, 5 * 60 * 1000);
  };

  const handleLogoutButton = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setLoggedIn(false);
    setIsLoggedIn({
      firstname: "",
      lastname: "",
      username: "",
    });
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setLoggedIn(false);
    setIsLoggedIn({
      firstname: "",
      lastname: "",
      username: "",
    });
  }
  
  document.addEventListener("mousemove", handleLogout);
  document.addEventListener("keypress", handleLogout);

  return (
    <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'row', 
        margin: '20px',
        marginTop: '50px',
        marginBottom: '30px', 
        justifyContent:'space-between',
        alignItems: 'center',
        width: 'auto',
      }}
    >
      <Box
        sx={{ 
          color: 'white', 
          textAlign: 'center', 
          fontSize: isTab ? '1.5rem' : '2.5rem',
        }}
      >
          {title}
      </Box>
      <Box>
        <LogoutButton handleLogout={handleLogoutButton} />
      </Box> 
    </Box>
  )
}

export default Header