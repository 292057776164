import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'

const LoadingIcon = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  
  return isVisible ? (
    <Box
      sx={{ 
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        display: 'grid',
        placeItems: 'center',
        position: 'fixed',
        zIndex: '10',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <Box 
        sx={{ 
          width: '30px',
          height: '15px',
        }}
      >
        <svg 
          xmlns='http://www.w3.org/2000/svg' 
          viewBox='0 0 300 150'
        >
          <path 
            fill='none' 
            stroke='#7aa648' 
            strokeWidth='20' 
            strokeLinecap='round' 
            strokeDasharray='300 385' 
            strokeDashoffset='0' 
            d='M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z'
          >
            <animate 
              attributeName='stroke-dashoffset' 
              calcMode='spline' 
              dur='2' 
              values='685;-685' 
              keySplines='0 0 1 1' 
              repeatCount='indefinite'
            >
            </animate>
          </path>
        </svg>
      </Box>
    </Box>
  ) : null;
};


export default LoadingIcon