import React, {useState} from 'react'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import LoginModal from '../modals/LoginModal'

const logo = "../../images/logos/logo_green.png"

const Intro = () => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const isTab = useMediaQuery('(max-width:770px)')

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  return (
    <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
        <IconButton
           onClick={openLoginModal}
        >
            <img 
                src={logo}
                alt='logo'
                style={{ 
                  width: isTab ? '100px' : '150px'
                 }}
            />
        </IconButton>
        <LoginModal open={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} />
    </Box>
  )
}

export default Intro