import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Intro from "./pages/Intro";
import Body from "./pages/Body";
import "../src/styles/index.css";
import { AuthContext } from "./contexts/AuthContext";

const App = () => {
  const { loggedIn } = useContext(AuthContext);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              loggedIn ? <Navigate to="/system" replace={true} /> : <Intro />
            }
          />
          <Route
            path="/system"
            element={!loggedIn ? <Navigate to="/" replace={true} /> : <Body />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
